import Vue from 'vue'
import store from '@/store';
// axios
import axios from 'axios'
import moment from 'moment'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: store.state.appConfig.layout.apiServer,
  // timeout: 1000,
  // headers: { 'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvd3d3LndhdGVybmV0LmxrXC9hcHBzXC93dW1cL2FwaVwvYXV0aFwvbG9naW4iLCJpYXQiOjE2NTY0NjkyNjAsImV4cCI6MTY1OTA5NzI2MCwibmJmIjoxNjU2NDY5MjYwLCJqdGkiOiJmRUpqVTBrSnZ6WndUOWR5Iiwic3ViIjoxLCJwcnYiOiIyZjQ1ODA5OTI3MDhlMDgwNTJhMzA3ZDMzZGM4ZmQ1NThkZmRhNGQ5In0.UFJHIgQ-jRYs9pAUhICvlO7tv5pReSDvNp6HjbhaQR8' }
})

// instance.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem('token')
//     const expired_at = localStorage.getItem('expired_at')
//     if (token && expired_at && moment.unix(expired_at).isAfter(moment())) {
//       config.headers.Authorization = `Bearer ${token}`
//     } else {
//       // Do something... Usually logout user.
//       console.log('session_expired');
//     }
//     return config
//   },
//   error => Promise.reject(error),
// )

Vue.prototype.$http = axiosIns

export default axiosIns
