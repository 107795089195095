export default [
  {
    path: '/dashboard/table',
    name: 'dashboard-table',
    component: () => import('@/views/dashboard/latest-status/LatestStatusPage.vue'),
    meta: {
      action: 'read',
      resource: 'table',
    },
  },
  {
    path: '/dashboard/map',
    name: 'dashboard-map',
    component: () => import('@/views/dashboard/map/MapPage.vue'),
    meta: {
      action: 'read',
      resource: 'map',
    },
  },
  {
    path: '/dashboard/tv-screen',
    name: 'dashboard-tv-screen',
    component: () => import('@/views/dashboard/tv-screen/TVScreenPage.vue'),
    meta: {
      action: 'read',
      resource: 'tv-screen',
      tvScreen: 'is-tv-screen',
      contentClass: 'is-tv-screen',
    },
  },
]
