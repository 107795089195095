import Vue from 'vue'
import VueRouter from 'vue-router'
import dashboard from './routes/dashboard'
import meters from './routes/meters'
import reports from './routes/reports'
import users from './routes/users'
import logs from './routes/logs'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import { canNavigate } from '@/libs/acl/routeProtection'
import billing from './routes/billing'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...dashboard,
    ...meters,
    ...users,
    ...reports,
    ...logs,
    ...billing,
    {
      path: '/',
      name: 'login-s',
      component: () => import('@/views/pages/Login.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'default',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/pages/Login.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'default',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/pages/Logout.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'default',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/pages/ForgotPassword.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'default',
      },
    },
    {
      path: '/reset-password/:token/:email',
      name: 'reset-password',
      component: () => import('@/views/pages/ResetPassword.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'default',
      },
    },
    // {
    //   path: '/error-404',
    //   name: 'error-404',
    //   component: () => import('@/views/error/Error404.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    {
      path: '*',
      redirect: 'login',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'login' })

    // If logged in => not authorized
    return next({ name: 'login' })
  }

  // Redirect if logged in
  // REMOVED:: 2022-07-19 as request
  // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
  //   const userData = getUserData()
  //   return next(getHomeRouteForLoggedInUser(userData ? userData.ability : null))
  // }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
