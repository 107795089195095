export default [
  {
    path: '/users/list',
    name: 'users-list',
    component: () => import('@/views/users/UsersListPage.vue'),
    meta: {
      action: 'read',
      resource: 'users',
    },
  },
]
