
import axios from '@axios'
import jwtAppConfig from './jwtAppConfig'
import JwtService from './jwtService'

const useJwt = (axiosIns, jwtOverrideConfig) => {
    const jwt = new JwtService(axiosIns, jwtOverrideConfig)

    return {
        jwt,
    }
}

const { jwt } = useJwt(axios, jwtAppConfig)
export default jwt