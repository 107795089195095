export default [
  {
    path: '/logs/list',
    name: 'logs-list',
    component: () => import('@/views/logs/LogListPage.vue'),
    meta: {
      action: 'read',
      resource: 'logs',
    },
  },
]
