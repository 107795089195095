<template>
  <div class="hidden">
    <li class="!bg-[#28C76F]" />
    <li class="!bg-[#00CFE8]" />
    <li class="!bg-[#FFC107]" />
    <li class="!bg-[#FF9F43]" />
    <li class="!bg-[#EA5455]" />
    <li class="!bg-[#857D81]" />
    <li class="!bg-[#1E1E1E]" />
    <li class="!bg-[#B8C2CC]" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      alarmColors: [
        '#28C76F',
        '#00CFE8',
        '#FFC107',
        '#FF9F43',
        '#EA5455',
        '#857D81',
        '#1E1E1E',
        '#B8C2CC',
      ],
    }
  },
}
</script>

<style></style>
