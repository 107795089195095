export default [
    {
        path: '/billing/invoices',
        name: 'billing-invoices',
        component: () => import('@/views/billing/invoices/InvoicesPage.vue'),
        meta: {
            action: 'read',
            resource: 'billing',
        },
    },
    {
        path: '/billing/generate',
        name: 'billing-generate',
        component: () => import('@/views/billing/generate/GeneratePage.vue'),
        meta: {
            action: 'create',
            resource: 'billing',
        },
    },
    {
        path: '/billing/settings',
        name: 'billing-settings',
        component: () => import('@/views/billing/settings/SettingsPage.vue'),
        meta: {
            action: 'read',
            resource: 'billing-settings',
        },
    },
]