import numeral from 'numeral';
import Vue from "vue"


export const formatConsumption = (value) => {
  return numeral(value).format("0,0.000")
}

export const replaceUndefined = (value) => {
  if (value == undefined) {
    return '--';
  }
  else {
    return value;
  }
}

export const formatCurrency = (value) => {
  return numeral(value).format("0,0.000")
}

Vue.filter('formatConsumption', formatConsumption);
Vue.filter('replaceUndefined', formatConsumption);

Vue.filter('formatCurrency', formatCurrency);