export default [
    {
      path: '/meters/list',
      name: 'meters-list',
      component: () => import('@/views/meters/MetersListPage.vue'),
      meta: {
        action: 'read',
        resource: 'meters',
      },
    },
  ]
