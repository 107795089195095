export default [
    {
        path: '/reports/single-consumer-consumption',
        name: 'reports-single-consumer-consumption',
        component: () => import('@/views/reports/single-consumer-consumption/SingleConsumerConsumptionPage.vue'),
        meta: {
            action: 'read',
            resource: 'reports',
        },
    },

    {
        path: '/reports/consumer-list',
        name: 'reports-consumer-list',
        component: () => import('@/views/reports/consumer-list/ConsumerListPage.vue'),
        meta: {
            action: 'read',
            resource: 'reports',
        },
    },

    {
        path: '/reports/consumption/consumer-type-wise',
        name: 'reports-consumer-type-wise-consumption',
        component: () => import('@/views/reports/consumption/consumer-type-wise/ConsumerTypeWiseConsumptionPage.vue'),
        meta: {
            action: 'read',
            resource: 'reports',
        },
    },

    {
        path: '/reports/consumption/aquifers-wise',
        name: 'reports-aquifers-wise-consumption',
        component: () => import('@/views/reports/consumption/aquifers-wise/AquifersWiseConsumptionPage.vue'),
        meta: {
            action: 'read',
            resource: 'reports',
        },
    },

    {
        path: '/reports/consumption/meter-size-wise',
        name: 'reports-meter-size-wise-consumption',
        component: () => import('@/views/reports/consumption/meter-size-wise/MeterSizeWiseConsumptionPage.vue'),
        meta: {
            action: 'read',
            resource: 'reports',
        },
    },

    {
        path: '/reports/consumption/water-source-wise',
        name: 'reports-water-source-wise-consumption',
        component: () => import('@/views/reports/consumption/water-source-wise/WaterSourceWiseConsumptionPage.vue'),
        meta: {
            action: 'read',
            resource: 'reports',
        },
    },

    {
        path: '/reports/consumption/area-wise',
        name: 'reports-area-wise-consumption',
        component: () => import('@/views/reports/consumption/area-wise/AreaWiseConsumptionPage.vue'),
        meta: {
            action: 'read',
            resource: 'reports',
        },
    },
]
